import React from "react";
import { List, Typography, Row, Col, Card } from "antd";
import { useGamerProfile } from "../../apiHooks";
import { _t } from "../../../Common/components/InjectIntlContext";
import { CurrencyLabel } from "../../../Common/components";

const FullWidthRow = ({ children }) => {
  return (
    <Row gutter={[10, 0]} style={{ width: "100%" }}>
      {children}
    </Row>
  );
};

const ListItem = ({ children }) => {
  return <List.Item style={{ paddingLeft: 0 }}>{children}</List.Item>;
};

export function SummaryInfo({ gamerId, projectId }) {
  const { data, isLoading } = useGamerProfile({
    gamer_id: gamerId,
    project_id: projectId,
  });

  if (isLoading) {
    return null;
  }

  return (
    <Row gutter={[30, 30]}>
      <Col span={12}>
        <Card>
          <List
            size="small"
            header={
              <Typography.Title level={4}>
                {_t("Registration details")}
              </Typography.Title>
            }
          >
            <ListItem>
              <FullWidthRow>
                <Col span={12}>{_t("id")}</Col>
                <Col span={12}>{data.id}</Col>
              </FullWidthRow>
            </ListItem>
            <ListItem>
              <FullWidthRow>
                <Col span={12}>{_t("reg_date")}</Col>
                <Col span={12}>{data.reg_date}</Col>
              </FullWidthRow>
            </ListItem>
            <ListItem>
              <FullWidthRow>
                <Col span={12}>{_t("Confirm Date")}</Col>
                <Col span={12}>{data.confirm_date}</Col>
              </FullWidthRow>
            </ListItem>
            <ListItem>
              <FullWidthRow>
                <Col span={12}>{_t("Promo")}</Col>
                <Col span={12}>{data.promo_code}</Col>
              </FullWidthRow>
            </ListItem>
            <ListItem>
              <FullWidthRow>
                <Col span={12}>{_t("SubID")}</Col>
                <Col span={12}>{data.sub_id}</Col>
              </FullWidthRow>
            </ListItem>
            <ListItem>
              <FullWidthRow>
                <Col span={12}>{_t("SubID2")}</Col>
                <Col span={12}>{data.sub_id2}</Col>
              </FullWidthRow>
            </ListItem>
            <ListItem>
              <FullWidthRow>
                <Col span={12}>{_t("Program")}</Col>
                <Col span={12}>{data.program_name}</Col>
              </FullWidthRow>
            </ListItem>
            <ListItem>
              <FullWidthRow>
                <Col span={12}>{_t("IP")}</Col>
                <Col span={12}>{data.reg_ip}</Col>
              </FullWidthRow>
            </ListItem>
            <ListItem>
              <FullWidthRow>
                <Col span={12}>{_t("Country")}</Col>
                <Col span={12}>{data.country}</Col>
              </FullWidthRow>
            </ListItem>
            <ListItem>
              <FullWidthRow>
                <Col span={12}>{_t("City")}</Col>
                <Col span={12}>{data.city}</Col>
              </FullWidthRow>
            </ListItem>
            <ListItem>
              <FullWidthRow>
                <Col span={12}>{_t("nick")}</Col>
                <Col span={12}>{data.nick}</Col>
              </FullWidthRow>
            </ListItem>
          </List>
        </Card>
      </Col>
      <Col span={12}>
        <Card>
          <List
            size="small"
            header={
              <Typography.Title level={4}>{_t("Activity")}</Typography.Title>
            }
          >
            <ListItem>
              <FullWidthRow>
                <Col span={12}>{_t("Deposits")}</Col>
                <Col span={12}>
                  <CurrencyLabel value={data.deposits_summary} />
                </Col>
              </FullWidthRow>
            </ListItem>
            <ListItem>
              <FullWidthRow>
                <Col span={12}>{_t("First deposit date")}</Col>
                <Col span={12}>{data.first_deposit_date}</Col>
              </FullWidthRow>
            </ListItem>
            <ListItem>
              <FullWidthRow>
                <Col span={12}>{_t("First deposit")}</Col>
                <Col span={12}>
                  <CurrencyLabel value={data.first_deposit} />
                </Col>
              </FullWidthRow>
            </ListItem>
            <ListItem>
              <FullWidthRow>
                <Col span={12}>{_t("Full income")}</Col>
                <Col span={12}>
                  <CurrencyLabel value={data.full_income} />
                </Col>
              </FullWidthRow>
            </ListItem>
            <ListItem>
              <FullWidthRow>
                <Col span={12}>{_t("Withdraws")}</Col>
                <Col span={12}>
                  <CurrencyLabel value={data.withdraws_summary} />
                </Col>
              </FullWidthRow>
            </ListItem>
            <ListItem>
              <FullWidthRow>
                <Col span={12}>{_t("Casino promo")}</Col>
                <Col span={12}>
                  <CurrencyLabel value={data.comppoints_summary} />
                </Col>
              </FullWidthRow>
            </ListItem>
            <ListItem>
              <FullWidthRow>
                <Col span={12}>{_t("Net Gaming")}</Col>
                <Col span={12}>
                  <CurrencyLabel value={data.ng_summary} />
                </Col>
              </FullWidthRow>
            </ListItem>
            <ListItem>
              <FullWidthRow>
                <Col span={12}>{_t("Qualification")}</Col>
                <Col span={12}>{data.qualification_date || "-//-"}</Col>
              </FullWidthRow>
            </ListItem>
            <ListItem>
              <FullWidthRow>
                <Col span={12}>{_t("Deposit Transactions Count")}</Col>
                <Col span={12}>{data.deposit_transaction_count}</Col>
              </FullWidthRow>
            </ListItem>
            <ListItem>
              <FullWidthRow>
                <Col span={12}>
                  <Typography.Text strong>
                    {_t("Multi accounts")}
                  </Typography.Text>
                </Col>
                <Col span={12}>
                  {data.mults?.length > 0 ? _t("Yes") : _t("No")}
                </Col>
              </FullWidthRow>
            </ListItem>
          </List>
        </Card>
      </Col>
    </Row>
  );
}
