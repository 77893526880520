import React, { useState } from "react";
import { Filters } from "../../../../../../../modules/Common/components/Filters";
import { useForm } from "antd/lib/form/Form";
import { Col, Form, Row, Select } from "antd";
import {
  PartnersSearch,
  ProgramsSelect,
  ProgramTypesSelect,
} from "../../../../../../../common/components";
import { selectDataProps } from "../../../../../Chat/helpers";
import { _t } from "../../../../../../../modules/Common/components/InjectIntlContext";
import { API_GET_BRANDS_LIST, API_KEY_GET_BRANDS_LIST } from "../../utils";
import DateRangePicker from "../../../../../../../common/components/DateRangePicker/DateRangePicker";
import { setFormInputValue } from "../../../../../../../common/helpers";
import { useGetDataWithoutParams } from "../../../../../../../common/hooks";

const ReportFilters = ({ setFilters, initialValues, excelButton }) => {
  const [form] = useForm();
  const {
    data: brandsData,
    isLoading: isBrandsLoading,
  } = useGetDataWithoutParams(API_KEY_GET_BRANDS_LIST, API_GET_BRANDS_LIST);
  const { Option } = Select;
  const [selectData, setSelectData] = useState<selectDataProps>();
  const [searchValue, setSearchValue] = useState<any[]>();
  const clearSearch = () => {
    setSearchValue([]);
    setSelectData({ data: [] });
  };
  const projectsOptions =
    brandsData &&
    Object.keys(brandsData.projects).map((item) => ({
      label: brandsData.projects[item].name,
      value: brandsData.projects[item].id,
    }));
  const sendFilters = (values) => {
    if (values.projects) values.projects.forEach((item) => +item);
    if (searchValue) values.partners = searchValue.map((item) => +item);
    setFilters(values);
  };
  return (
    <Filters
      form={form}
      onApply={sendFilters}
      initialValues={initialValues}
      mainFilters={
        <Row gutter={[10, 10]}>
          <Col span={24} xl={6}>
            <Form.Item name="date">
              <DateRangePicker
                onChange={(dates) => {
                  setFormInputValue(
                    "date",
                    {
                      from: dates[0],
                      to: dates[1],
                    },
                    form
                  );
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24} xl={6}>
            <Form.Item name="projects" label={_t("projects")}>
              <Select
                allowClear
                mode="multiple"
                options={projectsOptions}
                loading={isBrandsLoading}
                optionFilterProp="label"
              />
            </Form.Item>
          </Col>
          <Col span={24} xl={6}>
            <Form.Item name="partners" label={_t("partner")}>
              <PartnersSearch
                selectData={selectData}
                setSelectData={setSelectData}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                Option={Option}
                clearSearch={clearSearch}
                isMultiple
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={10}>
            <ProgramsSelect />
          </Col>
          <Col xs={24} md={12} xl={12}>
            <ProgramTypesSelect />
          </Col>
          <Col span={24} xl={2}>
            {excelButton}
          </Col>
        </Row>
      }
    />
  );
};

export default ReportFilters;
